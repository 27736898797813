<template>
    <svg viewBox="0 0 200 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M185.763 26.8644V12.6271H178.644V9.06778H185.763V1.94914H192.881V9.06778H200V12.6271H192.881V26.8644H185.763Z" fill="black"/>
        <path d="M150.17 26.8644V23.3051H157.288V5.50846H153.729V1.94914H164.407V23.3051H171.525V26.8644H150.17Z" fill="black"/>
        <path d="M121.695 26.8644V23.3051H118.136V9.06779H125.254V23.3051H135.932V9.06779H143.051V26.8644H121.695Z" fill="black"/>
        <path d="M96.7797 26.8644V23.3051H93.2204V19.7457H89.661V9.06778H93.2204V5.50846H96.7797V1.94914H111.017V5.50846H114.576V9.06778H107.458V5.50846H100.339V9.06778H96.7797V19.7457H100.339V23.3051H107.458V19.7457H114.576V23.3051H111.017V26.8644H96.7797Z" fill="black"/>
        <path d="M61.1865 30.4237V9.06779H82.5424V12.6271H86.1017V19.7458H82.5424V23.3051H68.3051V30.4237H61.1865ZM68.3051 19.7458H78.9831V12.6271H68.3051V19.7458Z" fill="black"/>
        <path d="M36.2712 26.8644V23.3051H32.7119V9.06779H39.8306V23.3051H50.5085V9.06779H57.6272V26.8644H36.2712Z" fill="black"/>
        <path d="M11.356 26.8644V23.3051H7.79665V19.7457H4.23733V9.06778H7.79665V5.50846H11.356V1.94914H25.5933V5.50846H29.1526V9.06778H22.0339V5.50846H14.9153V9.06778H11.356V19.7457H14.9153V23.3051H22.0339V19.7457H29.1526V23.3051H25.5933V26.8644H11.356Z" fill="black"/>
        <path d="M185.763 29.1525V14.9152H178.644V11.3559H185.763V4.23727H192.881V11.3559H200V14.9152H192.881V29.1525H185.763Z" fill="black"/>
        <path d="M150.17 29.1525V25.5932H157.288V7.79659H153.729V4.23727H164.407V25.5932H171.525V29.1525H150.17Z" fill="black"/>
        <path d="M121.695 29.1525V25.5932H118.136V11.3559H125.254V25.5932H135.932V11.3559H143.051V29.1525H121.695Z" fill="black"/>
        <path d="M96.7797 29.1525V25.5932H93.2204V22.0339H89.661V11.3559H93.2204V7.79659H96.7797V4.23727H111.017V7.79659H114.576V11.3559H107.458V7.79659H100.339V11.3559H96.7797V22.0339H100.339V25.5932H107.458V22.0339H114.576V25.5932H111.017V29.1525H96.7797Z" fill="black"/>
        <path d="M61.1865 32.7119V11.3559H82.5424V14.9152H86.1017V22.0339H82.5424V25.5932H68.3051V32.7119H61.1865ZM68.3051 22.0339H78.9831V14.9152H68.3051V22.0339Z" fill="black"/>
        <path d="M36.2712 29.1525V25.5932H32.7119V11.3559H39.8306V25.5932H50.5085V11.3559H57.6272V29.1525H36.2712Z" fill="black"/>
        <path d="M11.356 29.1525V25.5932H7.79665V22.0339H4.23733V11.3559H7.79665V7.79659H11.356V4.23727H25.5933V7.79659H29.1526V11.3559H22.0339V7.79659H14.9153V11.3559H11.356V22.0339H14.9153V25.5932H22.0339V22.0339H29.1526V25.5932H25.5933V29.1525H11.356Z" fill="black"/>
        <path d="M183.093 29.1525V14.9152H175.975V11.3559H183.093V4.23727H190.212V11.3559H197.331V14.9152H190.212V29.1525H183.093Z" fill="black"/>
        <path d="M147.5 29.1525V25.5932H154.619V7.79659H151.059V4.23727H161.737V25.5932H168.856V29.1525H147.5Z" fill="black"/>
        <path d="M119.025 29.1525V25.5932H115.466V11.3559H122.585V25.5932H133.263V11.3559H140.381V29.1525H119.025Z" fill="black"/>
        <path d="M94.1102 29.1525V25.5932H90.5509V22.0339H86.9916V11.3559H90.5509V7.79659H94.1102V4.23727H108.347V7.79659H111.907V11.3559H104.788V7.79659H97.6695V11.3559H94.1102V22.0339H97.6695V25.5932H104.788V22.0339H111.907V25.5932H108.347V29.1525H94.1102Z" fill="black"/>
        <path d="M58.517 32.7119V11.3559H79.8729V14.9152H83.4322V22.0339H79.8729V25.5932H65.6356V32.7119H58.517ZM65.6356 22.0339H76.3136V14.9152H65.6356V22.0339Z" fill="black"/>
        <path d="M33.6017 29.1525V25.5932H30.0424V11.3559H37.1611V25.5932H47.839V11.3559H54.9577V29.1525H33.6017Z" fill="black"/>
        <path d="M8.68648 29.1525V25.5932H5.12716V22.0339H1.56784V11.3559H5.12716V7.79659H8.68648V4.23727H22.9238V7.79659H26.4831V11.3559H19.3645V7.79659H12.2458V11.3559H8.68648V22.0339H12.2458V25.5932H19.3645V22.0339H26.4831V25.5932H22.9238V29.1525H8.68648Z" fill="black"/>
        <path d="M182.712 26.1017V11.8644H175.593V8.30507H182.712V1.18643H189.831V8.30507H196.949V11.8644H189.831V26.1017H182.712Z" fill="#C6FF53"/>
        <path d="M147.119 26.1017V22.5424H154.237V4.74575H150.678V1.18643H161.356V22.5424H168.475V26.1017H147.119Z" fill="#C6FF53"/>
        <path d="M118.644 26.1017V22.5424H115.085V8.30508H122.203V22.5424H132.881V8.30508H140V26.1017H118.644Z" fill="#C6FF53"/>
        <path d="M93.7288 26.1017V22.5424H90.1695V18.983H86.6102V8.30507H90.1695V4.74575H93.7288V1.18643H107.966V4.74575H111.525V8.30507H104.407V4.74575H97.2881V8.30507H93.7288V18.983H97.2881V22.5424H104.407V18.983H111.525V22.5424H107.966V26.1017H93.7288Z" fill="#C6FF53"/>
        <path d="M58.1356 29.661V8.30508H79.4915V11.8644H83.0509V18.983H79.4915V22.5424H65.2542V29.661H58.1356ZM65.2542 18.983H75.9322V11.8644H65.2542V18.983Z" fill="#C6FF53"/>
        <path d="M33.2204 26.1017V22.5424H29.661V8.30508H36.7797V22.5424H47.4576V8.30508H54.5763V26.1017H33.2204Z" fill="#C6FF53"/>
        <path d="M8.3051 26.1017V22.5424H4.74578V18.983H1.18645V8.30507H4.74578V4.74575H8.3051V1.18643H22.5424V4.74575H26.1017V8.30507H18.9831V4.74575H11.8644V8.30507H8.3051V18.983H11.8644V22.5424H18.9831V18.983H26.1017V22.5424H22.5424V26.1017H8.3051Z" fill="#C6FF53"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M181.525 27.2881V13.0508H174.407V7.11864H181.525V0H191.017V7.11864H198.136V13.0508H191.017V27.2881H181.525ZM189.831 11.8644H196.949V8.30507H189.831V1.18643H182.712V8.30507H175.593V11.8644H182.712V26.1017H189.831V11.8644Z" fill="#412ED0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M145.932 27.2881V21.3559H153.051V5.9322H149.492V0H162.542V21.3559H169.661V27.2881H145.932ZM161.356 22.5424V1.18643H150.678V4.74575H154.237V22.5424H147.119V26.1017H168.475V22.5424H161.356Z" fill="#412ED0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M118.644 26.1017H140V8.30508H132.881V22.5424H122.203V8.30508H115.085V22.5424H118.644V26.1017ZM117.458 23.7288V27.2881H141.186V7.11864H131.695V21.3559H123.39V7.11864H113.898V23.7288H117.458Z" fill="#412ED0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M92.5424 27.2881V23.7288H88.9831V20.1695H85.4237V7.11864H88.9831V3.55932H92.5424V0H109.153V3.55932H112.712V9.49152H103.22V5.9322H98.4746V9.49152H94.9153V17.7966H98.4746V21.3559H103.22V17.7966H112.712V23.7288H109.153V27.2881H92.5424ZM107.966 22.5424H111.525V18.983H104.407V22.5424H97.2881V18.983H93.7288V8.30507H97.2881V4.74575H104.407V8.30507H111.525V4.74575H107.966V1.18643H93.7288V4.74575H90.1695V8.30507H86.6102V18.983H90.1695V22.5424H93.7288V26.1017H107.966V22.5424Z" fill="#412ED0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M58.1356 29.661H65.2542V22.5424H79.4915V18.983H83.0509V11.8644H79.4915V8.30508H58.1356V29.661ZM80.678 10.678V7.11864H56.9492V30.8475H66.4407V23.7288H80.678V20.1695H84.2373V10.678H80.678ZM65.2542 18.983V11.8644H75.9322V18.983H65.2542ZM74.7458 17.7966H66.4407V13.0508H74.7458V17.7966Z" fill="#412ED0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M33.2204 26.1017H54.5763V8.30508H47.4576V22.5424H36.7797V8.30508H29.661V22.5424H33.2204V26.1017ZM32.0339 23.7288V27.2881H55.7627V7.11864H46.2712V21.3559H37.9661V7.11864H28.4746V23.7288H32.0339Z" fill="#412ED0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.11864 27.2881V23.7288H3.55932V20.1695H0V7.11864H3.55932V3.55932H7.11864V0H23.7288V3.55932H27.2881V9.49152H17.7966V5.9322H13.0508V9.49152H9.49152V17.7966H13.0508V21.3559H17.7966V17.7966H27.2881V23.7288H23.7288V27.2881H7.11864ZM22.5424 22.5424H26.1017V18.983H18.9831V22.5424H11.8644V18.983H8.3051V8.30507H11.8644V4.74575H18.9831V8.30507H26.1017V4.74575H22.5424V1.18643H8.3051V4.74575H4.74578V8.30507H1.18645V18.983H4.74578V22.5424H8.3051V26.1017H22.5424V22.5424Z" fill="#412ED0"/>
    </svg>
</template>
<script setup lang="ts">
</script>
