<template>
    <div>
        <nav class="fixed z-20 w-full top-0 border-b-2 border-black bg-white">
            <Container>
                <div class="flex h-20 py-6 items-center justify-between bg-white relative">
                    <div class="flex items-center">
                        <button
                            v-if="!registrationState"
                            class="p-1 mr-4 sm:mr-6"
                            :class="forceShowSidebar ? '' : 'lg:hidden'"
                            @click.prevent="$emit('open-menu')"
                            aria-label="Меню"
                        >
                            <Toaster width="24" height="24"/>
                        </button>
                        <div class="flex items-center lg:border-r-2 border-black lg:pr-5">
                            <Link href="/" aria-label="Головна">
                                <ApplicationLogo class="max-h-12 w-24 sm:w-40"/>
                            </Link>
                        </div>

                        <Catalog :menu="menu"/>
                    </div>

                    <div class="inline-flex items-center">
                        <div class="flex space-x-4 sm:space-x-6 font-bold">
                            <!--                                                <a class="inline-block mr-6 text-gray-900 hover:text-indigo-500 transition duration-200" href="#">-->
                            <!--                                                    <svg width="20" height="24" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                            <!--                                                        <path d="M14.44 0.100006C12.63 0.100006 11.01 0.980006 10 2.33001C8.99 0.980006 7.37 0.100006 5.56 0.100006C2.49 0.100006 0 2.60001 0 5.69001C0 6.88001 0.19 7.98001 0.52 9.00001C2.1 14 6.97 16.99 9.38 17.81C9.72 17.93 10.28 17.93 10.62 17.81C13.03 16.99 17.9 14 19.48 9.00001C19.81 7.98001 20 6.88001 20 5.69001C20 2.60001 17.51 0.100006 14.44 0.100006Z" fill="currentColor"></path>-->
                            <!--                                                    </svg>-->
                            <!--                                                </a>-->

                            <div
                                class="flex absolute w-full z-50 top-0 bg-white h-full items-center transition-all"
                                :class="isSearchActive ? 'left-0' : '-left-[200em]'"
                            >
                                <div class="w-full">
                                    <SelectInput
                                        id="search-input"
                                        placeholder="Я шукаю..."
                                        :search-route="route('api::search')"
                                        :hide-open-indicator="true"
                                        :clearSearchOnBlur="() => false"
                                        :filterable="false"
                                        :dropdownShouldOpen="dropdownShouldOpen"
                                        @searchUpdated="query = $event"
                                        :map-keydown="searchKeyDownHandlers"
                                        @option:selected="onSearchItemSelected"
                                        v-model="searchSelectedItem"
                                    />
                                </div>

                                <div
                                    class="min-w-12 h-12 bg-indigo-500 flex items-center text-white justify-center border-black border-2 border-l-0 cursor-pointer hover:bg-green-500 hover:text-black"
                                    @click="toSearchPage">
                                    <Search width="25"/>
                                </div>
                                <div
                                    class="min-w-12 h-12 flex items-center justify-center border-black border-2 border-l-0 cursor-pointer hover:text-red-500"
                                    @click="isSearchActive = false">
                                    <Cross width="25"/>
                                </div>
                            </div>

                            <Search
                                width="24"
                                class="hover:text-indigo-500 cursor-pointer"
                                @click="onSearchActive"
                            />

                            <div
                                v-if="!registrationState"
                                class="inline-flex items-center hover:text-indigo-500 cursor-pointer"
                                @click="$emit('open-cart')"
                            >
                                <div class="relative py-2">
                                    <div v-if="$page.props.cartProductsCount"
                                         class="absolute inline-flex items-center justify-center w-6 h-6 pt-0.5 text-xs text-white bg-red-500 border-white rounded-full -top-1 -right-3 dark:border-gray-900">
                                        {{ $page.props.cartProductsCount }}
                                    </div>

                                    <Cart/>
                                </div>
                            </div>
                            <Link
                                v-if="!registrationState"
                                :href="route('map')"
                                aria-label="Мапа"
                                class="relative group inline-flex items-center text-gray-900 hover:text-indigo-500 transition duration-200"
                            >
                                <div class="relative py-2">
                                    <Map width="24"/>
                                </div>
                                <span class="hidden lg:block ml-2">
                                Мапа
                            </span>
                            </Link>
                            <Link
                                v-if="!$page.props.auth.user"
                                :href="route('login')"
                                aria-label="Вхід / Реєстрація"
                                class="relative group inline-flex items-center text-gray-900 hover:text-indigo-500 transition duration-200"
                            >
                                <User width="24"/>
                                <span class="hidden sm:block ml-2">Вхід / Реєстрація</span>
                            </Link>
                            <button
                                v-else-if="!registrationState"
                                class="relative group inline-flex items-center text-left text-base text-black hover:text-indigo-500"
                            >
                                <User width="24"/>
                                <span class="hidden sm:block mx-2">{{ $page.props.auth.user.first_name }}</span>
                                <Chevron width="10" height="10" class="hidden sm:block rotate-90"/>
                                <div
                                    class="hidden group-hover:block absolute z-50 bottom-0 right-0 transform translate-y-full w-56 pt-6">
                                    <div class="p-2 bg-white border-2 border-black shadow">
                                        <p class="block p-2 text-sm text-black">
                                            Бонуси:
                                            <span class="text-indigo-500">{{ $page.props.auth.user.bonuses.formatted }}</span>
                                        </p>
                                        <hr class="border-b border-black my-2">
                                        <Link
                                            v-for="navItem in navItems"
                                            class="block p-2 text-sm text-black hover:text-indigo-500"
                                            :href="navItem.url"
                                            v-text="navItem.title"
                                        />
                                        <a
                                            v-if="$page.props.auth.hasCompanies"
                                            class="block p-2 text-sm text-black hover:text-indigo-500"
                                            :href="partnerUrl"
                                            target="_blank"
                                        >
                                            Кабінет продавця
                                        </a>

                                        <hr class="border-b border-black my-2">

                                        <!--                                    <Link-->
                                        <!--                                        :href="route('dashboard')"-->
                                        <!--                                        class="block p-2 text-sm text-black hover:text-indigo-500"-->
                                        <!--                                    >Особистий кабінет</Link>-->
                                        <Link
                                            :href="route('logout')"
                                            @click="logout"
                                            method="post"
                                            as="button"
                                            class="block p-2 text-sm text-black hover:text-indigo-500 w-full text-left"
                                        >Вихід
                                        </Link>
                                    </div>
                                </div>
                            </button>
                            <Link
                                v-else
                                :href="route('logout')"
                                @click="logout"
                                method="post"
                                as="button"
                            >
                                <span class="block ml-2">Вихід</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
        </nav>
    </div>
</template>

<script>
import {Link, router} from "@inertiajs/vue3";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import Toaster from "@/Components/Svg/Toaster.vue";
import Chevron from "@/Components/Svg/Chevron.vue";
import Map from "@/Components/Svg/Map.vue";
import Cart from "@/Components/Svg/Cart.vue";
import Container from "@/Components/Base/Container.vue";
import User from "@/Components/Svg/User.vue";
import {set} from "@/Helpers/zaraz";
import Search from "@/Components/Svg/Search.vue";
import SelectInput from "@/Components/SelectInput.vue";
import Cross from "@/Components/Svg/Cross.vue";
import vSelect from "vue-select";
import Catalog from "@/Layouts/Partials/Catalog.vue";

export default {
    components: {
        Catalog,
        vSelect,
        Cross,
        SelectInput,
        Search, User, Container, Cart, Map, Chevron, Toaster, ApplicationLogo, Link
    },
    props: {
        registrationState: Boolean,
        forceShowSidebar: Boolean,
        menu: Object,
    },
    data() {
        return {
            partnerUrl: import.meta.env.VITE_PARTNER_URL,
            query: '',
            isSearchActive: false,
            searchSelectedItem: null,
            navItems: [
                {
                    title: 'Мої замовлення',
                    url: this.route('orders.index'),
                },
                {
                    title: 'Особистий кабінет',
                    url: this.route('account.index'),
                }
            ],
        }
    },
    methods: {
        logout() {
            set('user_id', undefined)
            set('em', undefined)
            set('ph', undefined)
        },
        toSearchPage() {
            if (!this.query) return;

            router.visit(this.route('search'), {
                data: {q: this.query}
            });
        },
        onSearchItemSelected(option) {
            this.searchSelectedItem = null

            router.visit(this.route('products.show', option.value));
        },
        onSearchActive() {
            this.isSearchActive = true

            document.getElementById('search-input').focus()
        },
        searchKeyDownHandlers(map) {
            return {
                ...map,
                13: () => this.toSearchPage(),
            }
        },
        dropdownShouldOpen({open, mutableLoading}) {
            return this.query.length && !mutableLoading && open
        },
    },
}
</script>
