import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { ZiggyVue } from 'ziggy-js';
import {Ziggy as routes} from './ziggy'
import mitt from 'mitt'

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue')
        return pages[`./Pages/${name}.vue`]()
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, typeof Ziggy !== 'undefined' ? Ziggy : routes)

        app.config.globalProperties.emitter = mitt()

        return app.mount(el)
    },
    progress: {
        delay: 300,
        color: '#4B5563',
        showSpinner: true,
    },
});
